<template>
  <div class="d-flex flex-column pa-2 mt-4 full-width">
    !!!!! TODO
    <grid
      item-min-width="300px"
      class="align-center "
    >
      <v-text-field
        class="mx-2"
        :label="$t('t.Number#')"
        :value="number"
        readonly
      />
      <v-text-field
        class="mx-2"
        :label="$t('t.StartAt')"
        :value="startAt"
        readonly
      />
      <v-text-field
        class="mx-2"
        :label="$t('t.EndAt')"
        :value="endAt"
        readonly
      />
    </grid>

    <v-card
      class="my-2"
      v-for="(p, idx) in protocols"
      :key="idx"
    >
      <v-card-title>
        <document-name-ref
          :id="p.escalationProtocolId"
          :cache-type="cacheType"
        />
      </v-card-title>
      <v-card-text>
        <grid
          item-min-width="270px"
          class="align-center"
        >
          <v-text-field
            :label="$t('t.AccountsProcessed')"
            readonly
            :value="p.accountsProcessed"
          />
          <v-text-field
            :label="$t('t.AccountsEscalated')"
            readonly
            :value="p.accountsEscalated"
          />
          <v-text-field
            :label="$t('t.AccountsNewInEscalation')"
            readonly
            :value="p.accountsNewInEscalation"
          />
          <v-text-field
            :label="$t('t.AccountsNotEscalated')"
            readonly
            :value="p.accountsNotEscalated"
          />
          <v-text-field
            :label="$t('t.AccountsEscalatedToAnotherProtocol')"
            readonly
            :value="p.accountsEscalatedToAnotherProtocol"
          />
          <v-text-field
            class="ml-1"
            :label="$t('t.AccountsEndEscalated')"
            :value="p.accountsEndEscalated"
            readonly
          />
          <v-text-field
            :label="$t('t.AccountsInLastLevel')"
            readonly
            :value="p.accountsInLastLevel"
          />
          <v-text-field
            :label="$t('t.AccountsHavingUncompletedWorkItems')"
            readonly
            :value="p.accountsHavingUncompletedWorkItems"
          />
          <v-text-field
            :label="$t('t.AccountsMeetNegativeLevelTriggers')"
            readonly
            :value="p.accountsMeetNegativeLevelTriggers"
          />

          <v-text-field
            :label="$t('t.AccountsNotMeetingPositiveLevelCriteria')"
            readonly
            :value="p.accountsNotMeetingPositiveLevelCriteria"
          />
          <v-text-field
            :label="$t('t.AccountsNotPassingMinimumNumberOfDays')"
            readonly
            :value="p.accountsNotPassingMinimumNumberOfDays"
          />

        </grid>
      </v-card-text>
    </v-card>

  </div>
</template>

<script>
import { CacheType } from '@/wasm/pkg'

export default {
  activated () {
    this.$store.commit('setModule', {
      name: this.$t('t.ReminderRuns'),
      icon: 'i.ReminderRuns'
    })
  },
  components: {
    Grid: () => import('@/components/grid'),
    DocumentNameRef: () => import('@/components/documents-ref/document-name-ref')
  },
  computed: {
    number () {
      return this.data?.number
    },
    startAt () {
      return this.data?.startDate
    },
    endAt () {
      return this.data?.endDate
    },
    protocols () {
      return this.data?.escalationProtocols || []
    }
  },
  data () {
    return {
      data: null,
      cacheType: CacheType.EscalationProtocolRef
    }
  },
  methods: {
  },
  async mounted () {
    const url = '/core/v6/escalation/reminder-runs'
    const r = await this.$http().get(`${url}/${this.$route.params.id}`)
    this.data = r.data
  }

}
</script>
<style lang="stylus" scoped>
.full-width
  width 100%

.v-text-field
  min-width 250px
</style>
